import { IconSet, Notify } from 'quasar'
import { boot } from 'quasar/wrappers'
import {
  matCancel,
  matCheckCircle,
  matError,
  matKeyboardArrowDown,
  matKeyboardArrowRight,
  matWarning
} from '@quasar/extras/material-icons'

export default boot(() => {
  /**
   * Minify quasar icons as needed by [docs](https://quasar.dev/options/quasar-icon-sets#changing-a-specific-icon),
   * check all icons in [docs](https://quasar.dev/options/quasar-icon-sets#icon-set)
   */
  IconSet.props.field.clear = matCancel
  IconSet.props.field.error = matError
  IconSet.props.expansionItem.icon = matKeyboardArrowDown
  IconSet.props.table.warning = matWarning
  IconSet.props.tabs.right = matKeyboardArrowRight
  IconSet.props.arrow.dropdown = matKeyboardArrowDown

  // Notify predefined types https://quasar.dev/quasar-plugins/notify#predefined-types
  Notify.registerType('ok', {
    position: 'top',
    progress: false,
    icon: matCheckCircle,
    classes: 'q-notify-ok',
    color: '',
    textColor: 'white'
  })
  Notify.registerType('error', {
    position: 'top',
    progress: false,
    icon: matError,
    classes: 'q-notify-error',
    color: '',
    textColor: 'white'
  })
})
