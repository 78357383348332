import { api } from 'src/boot/axios'
import { useUserStore } from 'stores/user'

export function packageReq() {
  return api({
    url: 'package/all',
    method: 'post',
    data: {}
  })
}

export function TestTicket() {
  return api({
    url: 'notice/ticket',
    method: 'post',
    data: {}
  })
}

export const paymentTypeOption = [
  {
    id: 1,
    label: 'pricing.creditCard',
    value: 'BANK_CARD',
    color: 'yellow-9',
    icon: ['visa', 'mastercard']
  },
  {
    id: 2,
    label: 'pricing.unionPay',
    value: 'UNION_BANK_CARD',
    color: 'yellow-9',
    icon: ['unionpay']
  },
  {
    id: 3,
    label: 'pricing.crypto',
    value: 'crypto',
    color: 'yellow-9',
    icon: ['crypto-btc', 'crypto-eth', 'crypto-usdt']
  },
  {
    id: 4,
    label: 'pricing.alipay',
    value: 'alipay',
    color: 'yellow-9',
    icon: ['alipay']
  }
  // {
  //   id: 4,
  //   label: 'pricing.wechatPay',
  //   value: 'WECHAT_PAY',
  //   color: 'yellow-9',
  //   icon: ['wechatpay']
  // }
  // { label: 'pricing.paypal', value: 'PAYPAL', color: 'primary', icon: 'paypal' },
  // {
  //   label: 'pricing.googlePay',
  //   value: 'GOOGLE_PAY',
  //   color: 'primary',
  //   icon: ['google']
  // }
]

export function pageCommissionOrderReq(pageNum, pageSize) {
  return api({
    url: 'order/pageCommissionOrder',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      PageSize: pageSize,
      PageNum: pageNum
    }
  })
}
