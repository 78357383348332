<template>
  <q-page-container class="first-component">
    <div class="u-flex u-flex-col-reverse sm:u-flex-row">
      <div class="u-w-full sm:u-w-[60%] u-pb-10">
        <p class="u-text-[22px] md:u-text-[40px] bold-weight-font black-font">
          {{ $t('homePage.first.unlimited') }}
        </p>
        <p class="u-mt-10 sm:u-mt-20 u-text-[17px] md:u-text-[22px] black-font">
          {{ $t('homePage.first.highSpeed') }}
        </p>
        <p class="u-text-[17px] md:u-text-[22px] black-font">
          {{ $t('homePage.first.ensure') }}
        </p>
        <p class="u-text-[14px] md:u-text-[16px] black-font">
          {{ $t('homePage.first.hint') }}
        </p>
        <div class="u-grid sm:u-grid-cols-2 u-gap-5 u-pt-10 u-max-w-[468px]">
          <q-btn
            @click="scrollToDownload"
            class="u-bg-[#fcb400] u-min-h-[52px] u-font-bold u-text-[17px] md:u-text-[22px]"
            rounded
            :label="$t('global.download')" />
          <q-btn
            :to="
              userStore.token ? '/pricing?lang=' + userStore.lang : '/pricing'
            "
            class="u-bg-[#fcb400] u-min-h-[52px] u-font-bold u-text-[17px] md:u-text-[22px]"
            rounded
            :label="$t('global.purchase')" />
        </div>
      </div>
      <div
        class="u-w-full sm:u-w-[40%] u-flex u-flex-col u-items-center u-justify-center">
        <picture class="u-block u-aspect-[3/2] u-w-full u-h-auto">
          <source
            media="(min-width: 640px)"
            srcset="
              /img/home-first-component-sm@1x.webp 1x,
              /img/home-first-component-sm@2x.webp 2x,
              /img/home-first-component-sm@3x.webp 3x
            " />
          <source
            media="(min-width: 0px)"
            srcset="
              /img/home-first-component-xs@1x.webp 1x,
              /img/home-first-component-xs@2x.webp 2x,
              /img/home-first-component-xs@3x.webp 3x
            " />
          <img
            src="/img/home-first-component.webp"
            alt="home-first"
            class="u-w-full u-h-full" />
        </picture>
        <a
          style="text-decoration: underline; font-size: 10px"
          href="https://www.freepik.com/vectors/social-media-network"
          class="link">
          * GoDuck TLS is base on
          https://www.freepik.com/vectors/social-media-network
        </a>
      </div>
    </div>
    <!-- <p
      :class="'white-font ' + ($q.screen.width <= 768 ? 'text-13' : 'text-16')">
      {{ $t('homePage.first.hint') }}
    </p> -->
  </q-page-container>
</template>

<script>
import { defineComponent } from 'vue'
import { useQuasar } from 'quasar'
import { useUserStore } from 'src/stores/user'
import '../css/index.css'

export default defineComponent({
  setup() {
    const $q = useQuasar()
    const userStore = useUserStore()

    const scrollToDownload = () => {
      document.getElementById('download').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
    return {
      $q,
      scrollToDownload,
      userStore
    }
  }
})
</script>
