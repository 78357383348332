import script from "./IndexPage.vue?vue&type=script&setup=true&lang=js"
export * from "./IndexPage.vue?vue&type=script&setup=true&lang=js"

import "./IndexPage.vue?vue&type=style&index=0&id=2c2d87e9&lang=css"

const __exports__ = script;

export default __exports__
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPageContainer});
