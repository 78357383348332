<template>
  <q-page-container class="second-component">
    <div :class="$q.screen.width >= 500 ? 'row ' : 'column '">
      <div
        :class="
          $q.screen.width >= 500
            ? 'horizontal-flex-1 left-top'
            : 'phone_horizontal'
        ">
        <q-img src="/img/home-left-top.webp" />
      </div>
      <div
        :class="
          $q.screen.width >= 500
            ? 'horizontal-flex-1 right-top'
            : 'phone_horizontal'
        ">
        <p
          :class="
            ($q.screen.width <= 768 ? 'text-24' : 'text-45') +
            ' primary-font text-align-right  bold-weight-font'
          ">
          {{ $t('homePage.second.topTitle') }}
        </p>
        <div style="height: 15px" />
        <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') +
            ' black-font text-align-right'
          ">
          {{ $t('homePage.second.topDesc') }}
        </p>
      </div>
    </div>
    <div style="height: 50px" />
    <div :class="$q.screen.width >= 500 ? 'row ' : 'column reverse'">
      <div
        :class="
          $q.screen.width >= 500
            ? 'horizontal-flex-1 left-bottom'
            : 'phone_horizontal'
        ">
        <p
          :class="
            ($q.screen.width <= 768 ? 'text-24' : 'text-45') +
            ' primary-font  bold-weight-font'
          ">
          {{ $t('homePage.second.bottomTitle') }}
        </p>
        <div style="height: 15px" />
        <p
          :class="
            ($q.screen.width <= 768 ? 'text-18' : 'text-24') + ' black-font'
          ">
          {{ $t('homePage.second.bottomDesc') }}
        </p>
      </div>
      <div
        :class="
          $q.screen.width >= 500
            ? 'horizontal-flex-1 right-bottom'
            : 'phone_horizontal'
        ">
        <q-img id="right-bottom-img" src="/img/home-right-bottom.webp" />
      </div>
    </div>
  </q-page-container>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import '../css/index.css'
import { useUserStore } from 'src/stores/user'

export default defineComponent({
  setup() {
    const router = useRouter()
    const userStore = useUserStore()
    const changeUrl = (download) => {
      router.push({
        path: '/download',
        query: { platform: download, lang: userStore.lang }
      })
    }
    return {
      changeUrl,
      userStore
    }
  }
})
</script>
